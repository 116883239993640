:root {
  --toastify-toast-width: 420px!important;
}

html {
  width: 100%;
  height: 100%;
}

body {
  color: #20a0ff;
  background-size: 100%;
  background-color: #fff;
  max-width: 100%;
  overflow-x: hidden;
}

* {
  font-family: "ProximaNova", sans-serif !important;
  box-sizing: border-box;
}

.App > * {
  width: 100%;
  margin: 0;
}

.App-link {
  color: #61dafb;
}

h1 {
  margin: 0 0 15px;
  color: #20a0ff;
}
h3 {
  margin: 10px 0;
}
h4 {
  margin: 0;
}
h4 + span,
h4 + ul,
h4 + span > ul {
  padding: 0;
}

ul {
  list-style-type: none;
}

ul li,
p {
  color: black;
}

a:link {
  text-decoration: none;
}

table tr td {
  border-bottom: 1px solid #5ac8fa !important;
}

img {
  width: 75%;
}

.collapsedButton {
  border: none;
  background-color: transparent;
  color: red;
  font-size: 16px;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/src/fonts/light_aee13ii.eot");
  src: local("ProximaNova Light"), local("ProximaNova-Light"),
    url("/src/fonts/light_aee13ii.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/light_aee13ii.woff") format("woff2"),
    url("/src/fonts/light_aee13ii.woff") format("woff"),
    url("/src/fonts/light_aee13ii.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/src/fonts/regulr_97avv4l.eot");
  src: local("ProximaNova Regular"), local("ProximaNova-Regular"),
    url("/src/fonts/regulr_97avv4l.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/regulr_97avv4l.woff2") format("woff2"),
    url("/src/fonts/regulr_97avv4l.woff") format("woff"),
    url("/src/fonts/regulr_97avv4l.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/src/fonts/smbold_hjr52s7.eot");
  src: local("ProximaNova Semibold"), local("ProximaNova-Semibold"),
    url("/src/fonts/smbold_hjr52s7.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/smbold_hjr52s7.woff2") format("woff2"),
    url("/src/fonts/smbold_hjr52s7.woff") format("woff"),
    url("/src/fonts/smbold_hjr52s7.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/src/fonts/bold_g112aev.eot");
  src: local("ProximaNova Bold"), local("ProximaNova-Bold"),
    url("/src/fonts/bold_g112aev.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/bold_g112aev.woff2") format("woff2"),
    url("/src/fonts/bold_g112aev.woff") format("woff"),
    url("/src/fonts/bold_g112aev.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: block;
}

.leaflet-interactive {
    cursor: crosshair !important;
}
.Toastify__toast {
  border-radius: 6px;
}

.Toastify__progress-bar {
  height: 2px;
}

.MuiTableCell-paddingNone {
  padding: 0!important;
}

.leaflet-interactive {
  cursor: pointer!important;
}
